import {ApplicationConfig, importProvidersFrom, provideZoneChangeDetection} from '@angular/core';
import {provideRouter, withComponentInputBinding, withDebugTracing} from '@angular/router';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, provideHttpClient} from '@angular/common/http';
import {HttpAccessInterceptorProvider} from '../helpers/session-restart-interceptor';
import packageJson from "../../package.json";
import {CookieService} from 'ngx-cookie-service';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {MyMissingTranslationHandler} from '../helpers/MyMissingTranslationHandler';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  // console.log('>>> HttpLoaderFactory:', packageJson.version)

  return new TranslateHttpLoader(httpClient, '/assets/i18n/', '.json?ver=' +packageJson.version);
}

  export const appConfig: ApplicationConfig = {
    providers: [
      provideZoneChangeDetection({ eventCoalescing: true }),
      provideRouter(routes, withComponentInputBinding()),
    provideAnimationsAsync(),
    HttpAccessInterceptorProvider,
    CookieService,
    provideHttpClient(),
    importProvidersFrom(TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
      useDefaultLang: false
    })),

  ]
};

// supported languages
export const Languages : string[] = [ 'en', 'tw' ];
