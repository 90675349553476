import {Component, OnDestroy} from '@angular/core';
import {first, Subscription} from 'rxjs';
import {IResultwErrMsgResp, User} from '../../models';
import {LocalDateformatPipe} from '../../helpers/local-dateformat.pipe';
import {RedirectToQBLogin} from '../../helpers/tools';
import {AppComponent} from '../app.component';
import {TranslateModule} from '@ngx-translate/core';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-qbtoken',
  standalone: true,
  imports: [
    TranslateModule,
    NgIf
  ],
  templateUrl: './qbtoken.component.html',
  styleUrl: './qbtoken.component.css'
})
export class QbtokenComponent {
  error_msg='';
  info_msg =''
  loading = false;
  submitted = false;

  constructor(private appComponent: AppComponent,
  ) {
    this.getQBToken()
  }

  getQBToken() {
    this.loading = true;

    // get current QB token
    this.appComponent.userService.getQBToken().pipe(first()).subscribe({
      next: (res:IResultwErrMsgResp) => {
        // console.log('>>> getQBToken: res=%o', res)

        if(res.ok) {
          const json = JSON.parse(res.result);
          const createdAt:Date|undefined = json.createdAt
          const refreshTokenExpiresInDays = ((createdAt?.valueOf() || 0) +json['x_refresh_token_expires_in'] * 1000 -Date.now()) / 86400000;
          const accessTokenExpiresInMins = ((createdAt?.valueOf() || 0) +json['expires_in'] * 1000 - Date.now()) / 60000

          // console.log('>>> getQBToken: createdAt=%s, x_refresh_token_expires_in=%s, refreshTokenExpiresAt=%s',
          //   createdAt?.valueOf(), json['x_refresh_token_expires_in'], refreshTokenExpiresAt.valueOf())

          this.info_msg = `The current QB Token was created at ${LocalDateformatPipe.instant(createdAt)}`
            +`<br>Access token ${accessTokenExpiresInMins < 0 ? 'has expired for' : 'expires in'} ${Math.abs(accessTokenExpiresInMins).toFixed(1) } minutes`
            +`<br>Refresh token ${refreshTokenExpiresInDays < 0 ? 'has expired for' : 'expires in'} ${Math.abs(refreshTokenExpiresInDays).toFixed(1) } days`
        } else {
          this.error_msg = this.appComponent.translate.instant(res.result) +( res.errmsg ? '<br>' +res.errmsg : '')
        }
        this.loading = false
      },
      error: (err) => {
        this.error_msg = this.appComponent.translate.instant('error.connection') +'<br>' +err.toString();
        this.loading = false
      }
    })
  }

  onClickQboSignIn() {
    // login to QB
    this.info_msg = this.error_msg =''
    this.loading = true;
    RedirectToQBLogin()
  }

}
