import {Component, effect, inject, Input, input, OnDestroy, OnInit, signal} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {NgClass, NgIf} from "@angular/common";
import {Router} from "@angular/router";
import {globals} from "../../conf/globals";
import {AppComponent} from "../app.component";
import {first, Subscription} from "rxjs";
import {MatInputModule} from "@angular/material/input";
import {MatButton, MatButtonModule} from "@angular/material/button";
import {MatIcon, MatIconModule} from "@angular/material/icon";
import {User} from '../../models';


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    NgIf,
    NgClass,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit, OnDestroy {
  error_msg='';
  info_msg =''
  loginForm: FormGroup;
  loading = false;
  submitted = false;

  get f() { return this.loginForm.controls; }

  protected readonly _globals = globals;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private router: Router,
              ) {

    // console.log('>>> LoginComponent.constructor()');
    this.user = appComponent.userService.currentUserValue;

    this.loginForm = this.formBuilder.group({
      'email': ['', Validators.compose(
        [ Validators.required, Validators.email, Validators.maxLength(globals.maxFieldLength)]
      )],
      'password': ['', Validators.compose(
        [ Validators.required ]
      )],
    });

    this.allSubscriptions.push(appComponent.userService.currentUser.subscribe(u => {
      this.user = u;
      // console.log('>>> login: sub: user=%o', u);
      if(u.hasRole('ROLE_USER')) {
        // user has logged in successfully, reload root
        this.router.navigateByUrl('/');
      }
    }));

  }

  allSubscriptions: Subscription[] = [];

  user: User;
  passwordVisible ={password: false};

  onClickRevealPassword(event:any, name:string) {
    // console.log('onClickRevealPassword: name=%s, tagret=%o, %s', name, event.target, event.target instanceof HTMLButtonElement);

    event.preventDefault();
    // Prevent revealing the password when enter button is pressed.
    if (Object.hasOwn(this.passwordVisible, name)) {
      // @ts-ignore
      this.passwordVisible[name] = !this.passwordVisible[name];
    }
  }

  ngOnInit(): void {
    // console.log('>>> login.OnInit')

    // console.log('>>> location=%o', window.location);
    // console.log(window.location.protocol +'//' +window.location.host + '/login');
    this.appComponent.userService.checkSession()

    // console.log('author = %o', this.meta.updateTag({ name: 'author', content: 'XXX'}))

  }

  ngOnDestroy(): void {
    // console.log('>>> LoginComponent.ngOnDestroy()');

    while(this.allSubscriptions.length) {
      this.allSubscriptions.pop()?.unsubscribe();
    }
  }

  onSubmit() {
    this.info_msg = this.error_msg =''
    this.submitted =true;

    if(this.loginForm.invalid) {
    //   if(this.f['email']['errors']) console.log('!!! email error: %o', this.f['email']['errors']);
    //   if(this.f['password']['errors']) console.log('!!! password error: %o', this.f['password']['errors']);
      return;
    }

    this.loading =true;
    this.appComponent.userService.login(this.f['email']?.value, this.f['password']?.value)
      .pipe(first()).subscribe({
        next: () => {
          // done
          // this.router.navigateByUrl('/welcome', {skipLocationChange:true});

          this.appComponent.restartSessionTimer();

        }, error: err => {
          this.loading = false;
          this.error_msg = this.appComponent.translate.instant('error.login_failed');
        }
      }
    );
  }


}
