import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceSubject {
  accessHttp: Subject<string> =new Subject();
  constructor() { }
}
