import { Component } from '@angular/core';
import {AppComponent} from '../app.component';
import {first} from 'rxjs';
import {NgIf} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {MatInput} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-inventory-query',
  standalone: true,
  imports: [
    NgIf,
    TranslateModule,
    CdkTextareaAutosize,
    MatInput,
    ReactiveFormsModule
  ],
  templateUrl: './inventory-query.component.html',
  styleUrl: './inventory-query.component.css'
})
export class InventoryQueryComponent {
  error_msg='';
  info_msg =''
  loading = false;
  submitted = false;

  //debug
  // inventory_csv_gspath ='gs://projstatus-dev-storage888/upload/ericlam@nrg.com.hk_inventory_1729445155346.csv'
  inventory_csv_gspath =''

  constructor(private appComponent: AppComponent,
  ) {
    // this.getInventoryCsv()
  }

  getInventoryCsv() {
    this.error_msg = this.info_msg =''
    this.loading = true;

    // get current QB token
    this.appComponent.systemService.getQBInventorySummary().pipe(first()).subscribe({
      next: (res) => {
        // console.log('>>> getQBInventorySummary: res=%o', res)

        if(res.ok) {
          // this.inventory_csv = res?.result
          this.info_msg = `${res.infomsg}`
          if(res.output_csv_gs_path) {

            // gs://projstatus-dev-storage888/upload/ericlam@nrg.com.hk_inventory_1729445155346.csv
            this.inventory_csv_gspath = res.output_csv_gs_path

            if(res.infomsg) this.info_msg += '<br>'
            this.info_msg += `Output CSV to Google bucket <i>${res.output_csv_gs_path}</i> as backup`
          }
        } else {
          this.error_msg = this.appComponent.translate.instant(res.result) +( res.errmsg ? '<br>' +res.errmsg : '')
        }
        this.loading = false
      },
      error: (err) => {
        this.error_msg = this.appComponent.translate.instant('error.connection') +'<br>' +err.toString();
        this.loading = false
      }
    })
  }

  onClickDownloadCsv() {
    const url = window.location.origin + environment.api_url +'/downloadBucketFile?gsPath='
      +encodeURIComponent(this.inventory_csv_gspath)
    // console.log('Download CSV: %s', url);

    window.open(url, '_blank')
  }

}
