<form [formGroup]="form" class="text-center" >
  <h2 class="form-signin-heading my-3">{{ 'caption.edit_user' | translate}}</h2>

  <div *ngIf="error_msg" id="error_div" class="alert alert-danger" role="alert"
       [innerHTML]="error_msg">
  </div>

  <div *ngIf="info_msg" id="message_div" class="alert alert-success"
       [innerHTML]="info_msg">
  </div>

  <div class="text-sm-start mt-3">
    <a href="javascript:void(0)" [innerHTML]="'cation.go_back'|translate"
       (click)="onCloseEdit()">
    </a>
  </div>

  <div class="form-group mt-3">
    <div class="row g-3 align-text-top">
      <div class="col-auto">
        <mat-form-field>
          <mat-label  [innerHTML]=" 'caption.email' | translate "></mat-label>
          <input matInput type="text" id="email" formControlName="email"
                 maxlength="{{ _globals.maxFieldLength }}"
                 readonly
          >
        </mat-form-field>
      </div>

      <div class="col-auto">
        <mat-form-field>
          <mat-label  [innerHTML]=" 'caption.fullname' | translate "></mat-label>
          <input matInput type="text" id="fullName" formControlName="fullName"
                 [placeholder]="'caption.enter_fullname' | translate"
                 maxlength="{{ _globals.maxFieldLength }}"
          >
          @if(submitted && f['fullName']['errors']){
            <mat-error *ngIf="f['fullName']['errors']['required']">{{ 'error.must_fill_in' | translate }}</mat-error>
          }
        </mat-form-field>
      </div>

      <div class="col-auto">
        <mat-form-field>
          <mat-label  [innerHTML]=" 'caption.company' | translate "></mat-label>
          <input matInput type="text" id="company" formControlName="company"
                 [placeholder]="'caption.enter_company' | translate"
                 maxlength="{{ _globals.maxFieldLength }}"
          >
          @if(submitted && f['company']['errors']){
            <mat-error *ngIf="f['company']['errors']['required']">{{ 'error.must_fill_in' | translate }}</mat-error>
          }
        </mat-form-field>
      </div>

      <div class="col-auto">
        <mat-form-field>
          <mat-label [innerHTML]=" 'caption.role' | translate "></mat-label>
          <select id="role" formControlName="role"
                  (change)="onChangeRole($event)"
                  matNativeControl >
            <option value="ROLE_USER">ROLE_USER</option>
            <option value="ROLE_ADMIN">ROLE_ADMIN</option>
            <option value="ROLE_SUPERUSER">ROLE_SUPERUSER</option>
          </select>
        </mat-form-field>
      </div>

      <div class="col-auto">
        <mat-form-field>
          <mat-label  [innerHTML]=" 'caption.mobile' | translate "></mat-label>
          <input matInput type="text" id="mobile" formControlName="mobile"
                 [placeholder]="'caption.mobile' | translate"
          >

          @if(submitted && f['mobile']['errors']){
            <mat-error *ngIf="f['mobile']['errors']['required']">{{ 'error.must_fill_in' | translate }}</mat-error>
            <mat-error *ngIf="f['mobile']['errors']['minlength']">{{ 'error.field_min_len' | translate: {n: f['mobile']['errors']['minlength']['requiredLength']} }}</mat-error>
          }
        </mat-form-field>
      </div>

      <div class="col-auto">
        <mat-form-field>
          <mat-label [innerHTML]=" 'caption.isEnabled' | translate "></mat-label>
          <select id="isEnabled" formControlName="isEnabled"
                  matNativeControl >
            <option value="Y">Y</option>
            <option value="N">N</option>
          </select>
        </mat-form-field>
      </div>

      <div class="col-auto">
        <mat-form-field>
          <mat-label [innerHTML]=" 'caption.isAccNonLocked' | translate "></mat-label>
          <select id="isAccNonLocked" formControlName="isAccNonLocked"
                  matNativeControl >
            <option value="Y">Y</option>
            <option value="N">N</option>
          </select>
        </mat-form-field>
      </div>

      <div class="col-auto">
        <mat-form-field>
          <mat-label [innerHTML]=" 'caption.isAccNonExpired' | translate "></mat-label>
          <select id="isAccNonExpired" formControlName="isAccNonExpired"
                  matNativeControl >
            <option value="Y">Y</option>
            <option value="N">N</option>
          </select>
        </mat-form-field>
      </div>

      <div class="col-auto">
        <mat-form-field>
          <mat-label [innerHTML]=" 'caption.isPasswordNonExpired' | translate "></mat-label>
          <select id="isPasswordNonExpired" formControlName="isPasswordNonExpired"
                  matNativeControl >
            <option value="Y">Y</option>
            <option value="N">N</option>
          </select>
        </mat-form-field>
      </div>
    </div>

    <hr class="mt-3" />

    <div class="row g-3 align-text-top mt-3">
      <div class="col-6">
        <mat-form-field class="form-field-full">
          <mat-label class="sr-only" [innerHTML]="'caption.password' | translate"></mat-label>
          <input matInput [type]="passwordVisible.password ? 'text': 'password'" id="password" formControlName="password"
                 placeholder="{{ 'caption.password' | translate }}"
                 maxlength="{{ _globals.maxFieldLength }}"
          />
          <button mat-icon-button matSuffix
                  (click)="onClickRevealPassword($event, 'password')"
                  type="button"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="!passwordVisible.password">
            <mat-icon>{{passwordVisible.password ? 'visibility' : 'visibility_off'}}</mat-icon>
          </button>
          <mat-icon *ngIf="submitted && f['password']['errors']" matIconSuffix>error</mat-icon>
          @if(submitted && f['password']['errors']){
            <mat-error *ngIf="f['password']['errors']['required']">{{ 'error.password_is_reqd' | translate }}</mat-error>
            <mat-error *ngIf="f['password']['errors']['minlength']">{{ 'error.password_min_len' | translate: {n: f['password']['errors']['minlength']['requiredLength']} }}</mat-error>
            <mat-error *ngIf="f['password']['errors']['specialChars']" [innerHTML]="'error.no_spec_chars' | translate"></mat-error>
            <mat-error *ngIf="f['password']['errors']['noDigit']">{{ 'error.no_digit' | translate }}</mat-error>
            <mat-error *ngIf="f['password']['errors']['small_n_capital_letters']">{{ 'error.small_n_capital' | translate }}</mat-error>
          }
        </mat-form-field>

      </div>

      <div class="col-6">
        <mat-form-field class="form-field-full">
          <mat-label class="sr-only" [innerHTML]="'caption.confirm_password' | translate"></mat-label>
          <input matInput [type]="passwordVisible.confirm_password ? 'text': 'password'" id="confirm_password" formControlName="confirm_password"
                 placeholder="{{ 'caption.password' | translate }}"
                 maxlength="{{ _globals.maxFieldLength }}"
          />
          <button mat-icon-button matSuffix
                  (click)="onClickRevealPassword($event, 'confirm_password')"
                  type="button"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="!passwordVisible.confirm_password">
            <mat-icon>{{passwordVisible.confirm_password ? 'visibility' : 'visibility_off'}}</mat-icon>
          </button>
          <mat-icon *ngIf="submitted && f['password']['errors']" matIconSuffix>error</mat-icon>
          @if(submitted && (f['confirm_password']['errors'] || form.errors?.['unmatched_password'])){
            @if(f['confirm_password']['errors']?.['required']) {
              <mat-error >{{ 'error.must_fill_in' | translate }}</mat-error>
            } @else if (form.errors?.['unmatched_password']) {
              <mat-error >{{ 'error.confirm_password_mismatched' | translate }}</mat-error>
            }
          }
        </mat-form-field>
      </div>

    </div>

    <hr class="mt-3" />

  </div>

  <div class="col-auto text-sm-center">
    <button class="btn btn-sm btn-primary my-3"
            (click)="onClickUpdate($event)"
            type="button"
            [disabled]="!form.dirty"
    >
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ 'caption.update' | translate }}
    </button>
  </div>

</form>
