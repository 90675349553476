import {Component, OnDestroy} from '@angular/core';
import {NgIf} from "@angular/common";
import {CookieService} from "ngx-cookie-service";
import {User} from "../../models";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    NgIf,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent implements OnDestroy {
  showCookieConsent = true;
  cookieConsentName = 'cookieConsent';
  user = new User()

  constructor(private cookieService:CookieService,
              ) {
    // console.log('>>> footer.constructor')

    //debug
    // this.removeCookieConsent()

    let cookieConsent = this.cookieService.get(this.cookieConsentName);
    // console.log('>>> cookieConsent=%s', cookieConsent);
    this.showCookieConsent = !cookieConsent;
  }

  removeCookieConsent() {
    this.cookieService.delete(this.cookieConsentName);
    console.log(`>>> cookie ${this.cookieConsentName} removed`);
  }

  onClickDismissCookieConsent() {
    this.cookieService.set(this.cookieConsentName, '1')
    this.showCookieConsent = false;
  }

  ngOnDestroy(): void {
  }

}
