import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../environments/environment";
import {map} from "rxjs";
import {
  IResultAuditLogResp,
  IResultEstimatesResp,
  IResultInventoryResp,
  IResultResp,
  IResultStringArrayResp
} from "../models";


@Injectable({
  providedIn: 'root'
})

export class SystemService {

  constructor(
    private http: HttpClient,
  ) {
  }

  // get particular setting
  getSetting(varname :string) {
    const options = {
      withCredentials: true,
      params: new HttpParams().append('varname', varname)
    }

    // console.log('>>> getSetting: varname=%o', varname);

    return this.http.get(environment.api_url +'/getSysSetting', options)
      .pipe(map<any, IResultResp>(res => {
        return res;
      }));
  }

  // get the whole .ini contents
  getSettings() {
    return this.http.get(environment.api_url +'/getSysSettings', {withCredentials: true})
      .pipe(map<any, IResultResp>(res => {
        return res;
      }));
  }

  getTnC() {
    const href = window.location.href

    const options = {
      withCredentials: true,
      params: new HttpParams().append('href', window.location.href)
    }

    return this.http.get(environment.api_url +'/getTnC', options)
      .pipe(map<any, IResultResp>(res => {
        return res;
      }));
  }

  updateSettings(settings:string) {
    return this.http.post<any>(environment.api_url +'/updateSystemSettings',
      { settings },
      {withCredentials: true})
      .pipe(map<any, IResultResp>(res => {
        console.log('>>> updateSystemSettngs: %o', res);

        // return: { status: ok, result: i18n id }
        return res;
      }));
  }

  getAuditLog(startDate:Date, endDate:Date) {
    const queryParams =new HttpParams()
      .append('startDate', startDate.toISOString())
      .append('endDate', endDate.toISOString());

    // console.log('params=%o', queryParams)

    const options ={
      withCredentials: true,
      params: queryParams
    }

    return this.http.get(environment.api_url +'/getAuditLog', options)
      .pipe(map<any, IResultAuditLogResp>(res => {
        // console.log('>>> getAuditLog: %o', res);

        return res;
      }));
  }

  // getProfiles() {
  //   return this.http.get(environment.api_url +'/getProfiles', {withCredentials: true})
  //     .pipe(map<any, IResultStringArrayResp>(res => {
  //       return res;
  //     }));
  // }

  getEstimates(startDate:string, endDate:string, maxResults:number, startPos =1, estimate='',
               company='', po_num='', invoice_num:string, lastConditonsHash:string) {
    const queryParams =new HttpParams()
      .append('startDate', startDate)
      .append('endDate', endDate)
      .append('startPos', startPos)
      .append('maxResults', maxResults)
      .append('company', company)
      .append('estimate_num', estimate)
      .append('po_num', po_num)
      .append('invoice_num', invoice_num)
      .append('last_conditions_hash', lastConditonsHash)

    // console.log('>>> getEstimates: params=%o', queryParams)

    const options ={
      withCredentials: true,
      params: queryParams
    }

    return this.http.get(environment.api_url +'/getEstimates', options)
      .pipe(map<any, IResultEstimatesResp>(res => {
        // console.log('>>> getEstimates: %o', res);
        return res;
      }));
  }

  getQBInventorySummary() {
    // get QB token
    return this.http.get(environment.api_url +'/getQBInventorySummary', {withCredentials: true})
      .pipe(map<any, IResultInventoryResp>(res => {
        return res;
      }));
  }

}
