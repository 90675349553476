import {Component, OnDestroy} from '@angular/core';
import {AppComponent} from "../app.component";
import {TranslateModule} from "@ngx-translate/core";
import {first, Subscription} from "rxjs";
import {NgClass, NgIf} from "@angular/common";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {User} from '../../models';


@Component({
  selector: 'app-user-navbar',
  standalone: true,
  imports: [TranslateModule, NgIf, RouterLink, RouterLinkActive, NgClass],
  templateUrl: './user-navbar.component.html',
  styleUrl: './user-navbar.component.css'
})
export class UserNavbarComponent implements OnDestroy {
  constructor(private appComponent: AppComponent) {
    this.allSubscriptions.push(appComponent.userService.currentUser.subscribe(u => {
      this.user = u;
      //console.log('>>> user-nav: sub: user=%o', u);
    }));

    this.user =appComponent.userService.currentUserValue;
  }

  loading =false;

  allSubscriptions: Subscription[] = [];

  user = new User()

  collapseMenu = true;
  catalog_link ='';

  ngOnDestroy(): void {
    while(this.allSubscriptions.length) {
      this.allSubscriptions.pop()?.unsubscribe();
    }
  }

  logout() {
    // console.log("user-nav: logout clicked");

    this.loading =true;
    this.appComponent.userService.logout()
      .pipe(first()).subscribe( {
        next: () => {
          this.loading =false;
          // this.router.navigateByUrl('/login', {skipLocationChange: true});
        }, error: (err) => {
          this.loading =false;
          this.appComponent.userService.kickoutCurrentUser();
          // this.router.navigateByUrl('/login', {skipLocationChange: true});
        }
    });
  }

}
