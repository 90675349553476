import { Pipe, PipeTransform } from '@angular/core';
import {globals} from "../conf/globals";
import moment from "moment";

@Pipe({
  name: 'localDateformat',
  standalone: true
})
export class LocalDateformatPipe implements PipeTransform {

  // date: string "2023-12-18T01:36:37.668Z"
  transform(date: string|Date|undefined, format? :string): string {
    return LocalDateformatPipe.instant(date, format);
  }

  // get date/time according to globals.display_time_zone
  static instant(date: string|Date|undefined, format? :string): string {
    if(typeof date === "undefined") return '';

    if(typeof date === 'string') {
      date = new Date(date);
    }
    // local date in ms
    let lastUploadTimeInMS = date?.valueOf() ?? 0;

    // get time zone diff
    // display_time_zone=GMT+8
    let target_hour_offset = Number(globals.display_time_zone.substring(3));
    let diff_hour = target_hour_offset + (new Date()).getTimezoneOffset() / 60;

    // adjust to target offset
    lastUploadTimeInMS = lastUploadTimeInMS + diff_hour * 3600 * 1000;

    if(!format) format='YYYY-MM-DD (ddd) hh:mm:ss A';

    // '2023-11-06 (Mon) 5:37 AM'
    return moment(new Date(lastUploadTimeInMS)).format(format);
  }

  static reverseLocalToSystemTZ(date: Date|undefined|null): Date|undefined {
    if(!date) return undefined;

    // local date in ms
    let timeInMS = date?.valueOf() ?? 0;

    // get time zone diff
    // display_time_zone=GMT+8
    let target_hour_offset = Number(globals.display_time_zone.substring(3));
    let diff_hour = target_hour_offset + (new Date()).getTimezoneOffset() / 60;

    // adjust to target offset
    timeInMS -= diff_hour * 3600 * 1000;

    return new Date(timeInMS);
  }

  // fmt: e.g. 'YYYY-MM-DD'
  static formatDateFunc(date: Date|null|undefined, fmt : string) : string {
    return moment(date).format(fmt);
  }


}
