<div class="form-signin text-center" >

  <h2 class="form-signin-heading">{{ 'caption.inventory_summary' | translate}}</h2>

  <div *ngIf="error_msg" id="error_div" class="alert alert-danger" role="alert">
    <span [innerHTML]="error_msg"></span>
  </div>
  <div *ngIf="info_msg" id="message_div" class="alert alert-success"
       [innerHTML]="info_msg">
  </div>

  <div class="text-center d-inline-block" >
    <button [disabled]="loading" class="btn btn-primary btn-block" type="button"
            (click)="getInventoryCsv()">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ 'caption.export_inventory' | translate }}
    </button>
  </div>

  <hr />

  <div *ngIf="inventory_csv_gspath" class="text-start form-field-full d-block" >
    Click this <a href="javascript:void(0)" (click)="onClickDownloadCsv()" >link</a> to download the CSV file
  </div>

</div>
