<form [formGroup]="form" class="form-change-password text-center" (ngSubmit)="onSubmit()" >

  <h2 class="form-signin-heading">{{ title | translate}}</h2>

  <div *ngIf="error_msg" id="error_div" class="alert alert-danger" role="alert"
       [innerHTML]="error_msg">
  </div>

  <div *ngIf="info_msg" id="message_div" class="alert alert-success"
       [innerHTML]="info_msg">
  </div>


  <div class="text-start form-field-full" style="display: inline-block" >
    <div *ngIf="createNewUser" class="col-auto">
      <mat-form-field>
        <mat-label class="sr-only" [innerHTML]="'caption.email' | translate"></mat-label>
        <input matInput type="text" id="email" formControlName="email"
               placeholder="{{ 'caption.email' | translate }}"
               maxlength="{{ _globals.maxFieldLength }}"
               autofocus
        >
        <mat-icon *ngIf="submitted && f['email']['errors']" matIconSuffix>error</mat-icon>

        @if(submitted && f['email']['errors']){
          <mat-error *ngIf="f['email']?.['errors']?.['required']">{{ 'error.email_is_reqd' | translate }}</mat-error>
          <mat-error *ngIf="f['email']?.['errors']?.['email']">{{ 'error.invalid_email_format' | translate }}</mat-error>
        }
      </mat-form-field>
    </div>

    <div *ngIf="createNewUser" class="col-auto">
      <mat-form-field>
        <mat-label  [innerHTML]=" 'caption.fullname' | translate "></mat-label>
        <input matInput type="text" id="fullName" formControlName="fullName"
               maxlength="{{ _globals.maxFieldLength }}"
        >
        <mat-icon *ngIf="submitted && f['fullName']['errors']" matIconSuffix>error</mat-icon>

        @if(submitted && f['fullName']['errors']){
          <mat-error *ngIf="f['fullName']?.['errors']?.['required']">{{ 'error.must_fill_in' | translate }}</mat-error>
        }
      </mat-form-field>
    </div>

    <div *ngIf="createNewUser" class="col-auto">
      <mat-form-field>
        <mat-label  [innerHTML]=" 'caption.mobile' | translate "></mat-label>
        <input matInput type="text" id="mobile" formControlName="mobile"
               [placeholder]="'caption.mobile' | translate"
        >

        @if(submitted && f['mobile']['errors']){
          <mat-error *ngIf="f['mobile']?.['errors']?.['required']">{{ 'error.must_fill_in' | translate }}</mat-error>
          <mat-error *ngIf="f['mobile']?.['errors']?.['minlength']">{{ 'error.field_min_len' | translate: {n: f['mobile']['errors']['minlength']['requiredLength']} }}</mat-error>
        }
      </mat-form-field>
    </div>

    <div *ngIf="createNewUser" class="col-auto">
      <mat-form-field>
        <mat-label  [innerHTML]=" 'caption.company' | translate "></mat-label>
        <input matInput type="text" id="company" formControlName="company"
               [placeholder]="'caption.enter_company' | translate"
               maxlength="{{ _globals.maxFieldLength }}"
        >
        @if(submitted && f['company']['errors']){
          <mat-error *ngIf="f['company']?.['errors']?.['required']">{{ 'error.must_fill_in' | translate }}</mat-error>
        }
      </mat-form-field>
    </div>

    <div class="form-field-full">
      <mat-form-field class="form-field-full">
        <mat-label class="sr-only" [innerHTML]="'caption.new_password' | translate"></mat-label>
        <input matInput [type]="passwordVisible.password ? 'text': 'password'" id="password" formControlName="password"
               placeholder="{{ 'caption.new_password' | translate }}"
               maxlength="{{ _globals.maxFieldLength }}"
        />
        <button mat-icon-button matSuffix
                (click)="onClickRevealPassword($event, 'password')"
                type="button"
                [attr.aria-label]="'Hide password'"
        >
          <mat-icon>{{passwordVisible.password ? 'visibility' : 'visibility_off'}}</mat-icon>
        </button>
        <mat-icon *ngIf="submitted && f['password']['errors']" matIconSuffix>error</mat-icon>
        @if(submitted && f['password']['errors']){
          <mat-error *ngIf="f['password']?.['errors']?.['required']">{{ 'error.password_is_reqd' | translate }}</mat-error>
          <mat-error *ngIf="f['password']?.['errors']?.['minlength']">{{ 'error.password_min_len' | translate: {n: f['password']['errors']['minlength']['requiredLength']} }}</mat-error>
          <mat-error *ngIf="f['password']?.['errors']?.['specialChars']" [innerHTML]="'error.no_spec_chars' | translate"></mat-error>
          <mat-error *ngIf="f['password']?.['errors']?.['noDigit']">{{ 'error.no_digit' | translate }}</mat-error>
          <mat-error *ngIf="f['password']?.['errors']?.['small_n_capital_letters']">{{ 'error.small_n_capital' | translate }}</mat-error>
        }
      </mat-form-field>
    </div>

    <div class="form-field-full">
      <mat-form-field class="form-field-full">
        <mat-label class="sr-only" [innerHTML]="'caption.confirm_new_password' | translate"></mat-label>
        <input matInput [type]="passwordVisible.confirm_password ? 'text': 'password'" id="confirm_password" formControlName="confirm_password"
               placeholder="{{ 'caption.confirm_new_password' | translate }}"
               maxlength="{{ _globals.maxFieldLength }}"
        />
        <button mat-icon-button matSuffix
                (click)="onClickRevealPassword($event, 'confirm_password')"
                type="button"
                [attr.aria-label]="'Hide password'"
        >
          <mat-icon>{{passwordVisible.confirm_password ? 'visibility' : 'visibility_off'}}</mat-icon>
        </button>
        <mat-icon *ngIf="submitted && f['password']?.['errors']" matIconSuffix>error</mat-icon>
        @if(submitted && (f['confirm_password']['errors'] || form.errors?.['unmatched_password'])){
          @if(f['confirm_password']['errors']?.['required']) {
            <mat-error >{{ 'error.must_fill_in' | translate }}</mat-error>
          } @else if (form.errors?.['unmatched_password']) {
            <mat-error >{{ 'error.confirm_password_mismatched' | translate }}</mat-error>
          }
        }
      </mat-form-field>
    </div>

  </div>

  <div class="form-group">
    <button [disabled]="loading" class="btn btn-primary btn-block mt-3" type="submit">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ 'caption.submit' | translate }}
    </button>
  </div>

</form>
