import {Component, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AppComponent} from "../app.component";
import {Router} from "@angular/router";
import {LocalDateformatPipe} from "../../helpers/local-dateformat.pipe";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgForOf, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {globals} from "../../conf/globals";
import {getRoleName, IUser} from "../../models";
import {first} from "rxjs";
import {UserEditComponent} from "../user-edit/user-edit.component";
import {compareFunc} from "../../helpers/compareFunc";
import {NumberCommasPipe} from "../../helpers/number_commas.pipe";

@Component({
  selector: 'app-user-admin',
  standalone: true,
  imports: [
    FormsModule,
    LocalDateformatPipe,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    UserEditComponent,
    NumberCommasPipe
  ],
  templateUrl: './user-admin.component.html',
  styleUrl: './user-admin.component.css'
})
export class UserAdminComponent implements OnInit {
  error_msg = '';
  info_msg = '';
  form: FormGroup;

  loading = false;
  submitted = false;

  recUsers: Partial<IUser>[] = [];
  filteredRecUsers: Partial<IUser>[] = [];

  showTableForm = true;
  showEditForm = false;

  userForEdit: Partial<IUser>|undefined = undefined;

  profileOptions: {val:string, display:string}[] =[];

  get f() {return this.form.controls;}

  protected readonly _globals = globals;
  protected readonly getRoleName = getRoleName;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private router: Router
  ) {
    this.form = this.formBuilder.group({
      user: [''],
      aliases: this.formBuilder.array([]),
    });
  }


  ngOnInit() {
    this.reloadData();
  }

  onClickApplyFilter(event: any) {
    event.preventDefault();
    this.filterData();
  }

  filterData() {
    console.log('>>> filterData()');

    this.loading =true;

    let wantedUserParts: string[] =this.f['user'].value.toLowerCase().replace(/\s+/,' ').split(' ')

    if(wantedUserParts.length === 1 && wantedUserParts[0].length === 0) wantedUserParts =[];

    if(wantedUserParts.length > 0) {
      this.filteredRecUsers = this.recUsers.filter((rec, i) => {
        // check email / name
        if (wantedUserParts.length > 0) {
          const email_n_name = (rec.email?.toLowerCase() ?? '') + (rec.fullName?.toLowerCase() ?? '');
          if (!email_n_name || !wantedUserParts.every(part => email_n_name.indexOf(part) >= 0)) return false;
        }

        return true;
      })
    } else {
      this.filteredRecUsers = this.recUsers;
    }

    this.submitted = this.loading =false;
  }

  reloadData() {
    console.log('>>> reloadData()');

    this.loading =true;

   this.appComponent.userService.getAllUsers([], true)
      .pipe(first()).subscribe({
      next: (res) => {
        // done
        if(res.ok) {
          // console.log('>>> res = %o', res);

          this.recUsers = res.users.sort( (a,b) => compareFunc(a?.email ?? '', b?.email ?? ''));
          this.filterData();
        } else {
          this.error_msg = this.appComponent.translate.instant('error.unable_to_load_rec');
          this.submitted = this.loading =false;
        }

      }, error: err => {
        this.error_msg = this.appComponent.translate.instant('error.unable_to_load_rec');
        this.submitted = this.loading =false;
      }
    });

  }

  onCloseEditForm(event:any) {
    console.log('event=%o', event);

    this.showEditForm =false;
    this.showTableForm =true;
    this.userForEdit = undefined;
    this.reloadData();

  }
  onClickUser(user:Partial<IUser>|undefined) {
    console.log('onClickUser: userId=%s', user?.id);
    this.showTableForm =false;

    this.userForEdit = user;
    this.showEditForm =true;
  }

}
