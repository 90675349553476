import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable, Provider} from "@angular/core";
import {Observable} from "rxjs";
import {HttpServiceSubject} from "../services/http-service-subject";

@Injectable()
export class SessionRestartInterceptor implements HttpInterceptor {
  constructor(private httpService: HttpServiceSubject) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('>>> httpInterceptor');

    // this.appComponent.restartSessionTimer();
    this.httpService.accessHttp.next('x');

    return next.handle(req);
  }

}

/** Provider for the Noop Interceptor. */
export const HttpAccessInterceptorProvider: Provider =
  { provide: HTTP_INTERCEPTORS, useClass: SessionRestartInterceptor, multi: true };
