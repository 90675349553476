import { Routes } from '@angular/router';
import {WelcomeComponent} from "./welcome/welcome.component";
import {authGuard} from "../helpers/auth.guard";
import {LoginComponent} from "./login/login.component";
// import {RegisterUserComponent} from "./register-user/register-user.component";
// import {ApproveNewUserComponent} from "./approve-new-user/approve-new-user.component";
import {ChangePasswordComponent} from "./change-password/change-password.component";
import {AppsettingsComponent} from "./appsettings/appsettings.component";
import {AuditLogComponent} from "./audit-log/audit-log.component";
import {UserAdminComponent} from "./user-admin/user-admin.component";
import {OrdersComponent} from './orders/orders.component';
import {QbtokenComponent} from './qbtoken/qbtoken.component';
import {InventoryQueryComponent} from './inventory-query/inventory-query.component';
// import {ForgotPasswordComponent} from "./forgot-password/forgot-password.component";
// import {ProfileComponent} from "./profile/profile.component";

export const routes: Routes = [
  { path: '', component: WelcomeComponent, canActivate: [authGuard('ROLE_USER')] },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'qb_token', component: QbtokenComponent, canActivate: [authGuard('ROLE_ADMIN')] },
  { path: 'search_orders', component: OrdersComponent, canActivate: [authGuard('ROLE_USER')] },
  { path: 'inventory_summary', component: InventoryQueryComponent, canActivate: [authGuard('ROLE_ADMIN')] },
  // { path: 'register_user', component: RegisterUserComponent },
  // { path: 'forgot_password', component: ForgotPasswordComponent },
  { path: 'newuser', component: ChangePasswordComponent, canActivate: [authGuard('ROLE_USER')] },
  // { path: 'resetpasswd', component: ChangePasswordComponent },
  { path: 'password', component: ChangePasswordComponent, canActivate: [authGuard('ROLE_USER')] },
  // { path: 'profile', component: ProfileComponent, canActivate: [authGuard('ROLE_USER')] },
  // { path: 'approve_new_user', component: ApproveNewUserComponent, canActivate: [authGuard('ROLE_ADMIN')] },
  { path: 'appsettings', component: AppsettingsComponent, canActivate: [authGuard('ROLE_SUPERUSER')] },
  { path: 'auditLog', component: AuditLogComponent, canActivate: [authGuard('ROLE_SUPERUSER')] },
  { path: 'userAdmin', component: UserAdminComponent, canActivate: [authGuard('ROLE_SUPERUSER')] },
  { path: '**', redirectTo: '/' }
];
