<form [formGroup]="form" class="text-center" (ngSubmit)="onSubmit()" >

  <h2 class="form-signin-heading">{{ 'caption.appsettings' | translate}}</h2>

  <div *ngIf="error_msg" id="error_div" class="alert alert-danger" role="alert"
       [innerHTML]="error_msg">
  </div>

  <div *ngIf="info_msg" id="message_div" class="alert alert-success"
       [innerHTML]="info_msg">
  </div>

  <div *ngIf="f['settings']?.value" class="text-start form-field-full d-block" >
    <textarea matInput id="settings" formControlName="settings" class="mb-1 form-field-full "
              cdkTextareaAutosize
              cdkAutosizeMinRows="10" cdkAutosizeMaxRows="20"
    ></textarea>
  </div>

  <div class="form-group">
    <button [disabled]="loading || !f['settings'].dirty"
            class="btn btn-primary btn-block mt-3"
            type="submit"
    >
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ 'caption.update' | translate }}
    </button>
  </div>

</form>
