<div class="form-signin text-center" >

  <h2 class="form-signin-heading">{{ 'pls.sign.in.qbo' | translate}}</h2>

  <div *ngIf="error_msg" id="error_div" class="alert alert-danger" role="alert">
    <span [innerHTML]="error_msg"></span>
  </div>
  <div *ngIf="info_msg" id="message_div" class="alert alert-success"
       [innerHTML]="info_msg">
  </div>

  <div class="text-center d-inline-block" >
    <button [disabled]="loading" class="btn btn-danger btn-block" type="button"
            (click)="onClickQboSignIn()">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ 'caption.retake_qb_token' | translate }}
    </button>
  </div>
</div>

