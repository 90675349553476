<form [formGroup]="form" class="text-center" (ngSubmit)="onSubmit()" >

  <h2 class="form-signin-heading">{{ 'caption.audit_log.description' | translate}}</h2>

  <div *ngIf="error_msg" id="error_div" class="alert alert-danger" role="alert"
       [innerHTML]="error_msg">
  </div>

  <div *ngIf="info_msg" id="message_div" class="alert alert-success"
       [innerHTML]="info_msg">
  </div>

  <div class="form-group">
    <div class="row g-3 align-text-top">
      <div class="col-auto">
        <mat-form-field>
          <mat-label [innerHTML]="'caption.select_date_range' | translate"></mat-label>

          <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker" >
            <input matStartDate formControlName="start" [placeholder]="'caption.start_date' | translate ">
            <input matEndDate formControlName="end" [placeholder]="'caption.end_date' | translate ">
          </mat-date-range-input>

          <mat-hint>YYYY-MM-DD - YYYY-MM-DD</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="submitted && dateRange?.['errors']?.['dates_not_set']">
            {{ 'error.dates_unset' | translate }}
          </mat-error>

        </mat-form-field>
      </div>
      <div class="col-auto align-top">
        <button class="btn btn-primary mt-3"
                type="submit">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ 'caption.search_auditlog' | translate }}
        </button>
      </div>

    </div>

    <hr class="my-3" />

    <h5 class="form-signin-heading">{{ 'caption.filters' | translate}}</h5>

    <div class="row g-3 align-text-top">
      <div class="col-auto">
        <mat-form-field>
          <mat-label  [innerHTML]=" 'caption.user' | translate "></mat-label>
          <input matInput type="text" id="user" formControlName="user"
                 placeholder="{{ 'caption.enter_user_for_searching' | translate }}"
                 maxlength="{{ _globals.maxFieldLength }}"
                 (keydown.enter)="$event.preventDefault()"
          >
        </mat-form-field>
      </div>

      <div class="col-auto">
        <mat-form-field>
          <mat-label  [innerHTML]=" 'caption.company' | translate "></mat-label>
          <input matInput type="text" id="company" formControlName="company"
                 placeholder="{{ 'caption.enter_company_for_searching' | translate }}"
                 maxlength="{{ _globals.maxFieldLength }}"
                 (keydown.enter)="$event.preventDefault()"
          >
        </mat-form-field>
      </div>

      <div class="col-auto">
        <mat-form-field>
          <mat-label  [innerHTML]=" 'caption.details' | translate "></mat-label>
          <input matInput type="text" id="details" formControlName="details"
                 placeholder="{{ 'caption.enter_details_for_searching' | translate }}"
                 maxlength="{{ _globals.maxFieldLength }}"
                 (keydown.enter)="$event.preventDefault()"
          >
        </mat-form-field>
      </div>

      <div class="col-auto">
        <mat-form-field>
          <mat-label  [innerHTML]=" 'caption.action' | translate "></mat-label>
          <select id="user" formControlName="action"
                  matNativeControl
          >
            <option *ngFor="let option of actionsOptions" [value]="option.val" [innerHTML]="option.display"></option>
          </select>
        </mat-form-field>
      </div>

      <div class="col-auto ">
        <button class="btn btn-primary mt-3"
                (click)="onClickApplyFilter($event)"
                type="button">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ 'caption.apply_filter' | translate }}
        </button>
      </div>

    </div>

    <div *ngIf="filteredAuditLogs" class="mt-2">
      <table class="table table-secondary table-bordered" >
        <thead class="table-primary fw-bolder nrg-table-header">
        <tr>
          <td>#</td>
          <td [innerHTML]="'caption.datetime' | translate" ></td>
          <td [innerHtml]="'caption.user_details' | translate" ></td>
          <td [innerHtml]="'caption.remote_host' | translate" ></td>
          <td [innerHtml]="'caption.action' | translate" ></td>
          <td [innerHtml]="'caption.details' | translate" ></td>
        </tr>
        </thead>

        <tbody class="nrg-table-body">
          @for(rec of filteredAuditLogs; let i=$index; track rec.id ) {
            <tr>
              <td>{{ i+1 }}</td>
              <td [innerHTML]=" rec.createdOn | localDateformat:'YYYY-MM-DD hh:mm A' " ></td>
              <td [innerHTML]=" rec.email +'<br>'+ rec.fullName +'<br>['+ rec.company +']' "></td>
              <td [innerHTML]=" rec.remoteHost "></td>
              <td [innerHTML]=" rec.action "></td>
              <td class="text-sm-start" [innerHTML]=" rec.details "></td>
            </tr>
          }
        </tbody>
      </table>
    </div>

  </div>

</form>
