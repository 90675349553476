import {Component, OnInit} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DATE_FORMATS, globals} from '../../conf/globals';
import {MatInputModule} from '@angular/material/input';
import {DecimalPipe, NgClass, NgForOf, NgIf} from '@angular/common';
import {
  AbstractControl, FormBuilder, FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import {TextFieldModule} from '@angular/cdk/text-field';
import {TranslateModule} from '@ngx-translate/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {LocalDateformatPipe} from '../../helpers/local-dateformat.pipe';
import {MatButtonModule} from '@angular/material/button';
import {first} from 'rxjs';
import {
  ICustomField,
  ILinkedTxn,
  IItemRef,
  ILine,
  IMetaData,
  IDeliveryInfo,
  IEstimate,
  IInvoice, IInvoicedItems, IInvoiceId_n_Line, User,
} from '../../models';
import {AppComponent} from '../app.component';
import {Router, RouterLink} from '@angular/router';
import {environment} from '../../environments/environment';
import {RedirectToQBLogin} from '../../helpers/tools';

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [
    MatInputModule,
    NgIf,
    ReactiveFormsModule,
    TextFieldModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    FormsModule,
    MatNativeDateModule,
    NgForOf,
    LocalDateformatPipe,
    MatButtonModule,
    DecimalPipe,
    NgClass,
    RouterLink,
  ],
  providers: [
    { provide: DateAdapter, useClass:MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.css'
})
export class OrdersComponent implements OnInit {
  error_msg = '';
  info_msg = '';
  form: FormGroup;

  loading = false;
  submitted = false;

  protected readonly _globals = globals;

  get f() {return this.form.controls;}

  filteredEstimates: IEstimate[]|undefined =undefined;
  recLinkedInvoices: { [key:string]: IInvoice } ={};

  maxResults= 20    // page count for no. of estimates to be shown

  orgReqStartDate: string ='';
  orgReqEndDate: string ='';
  nextDbStartPos: number =1;
  lastConditionsHash =''
  lastSearchDurInMs =0

  page_entering_url = ''

  user : User|undefined =undefined;

  dateRange =new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null) })
  // }, [ this.checkDateRangeValidator() ]);

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private router: Router
  ) {
    this.user = appComponent.userService.currentUserValue

    this.form = this.formBuilder.group({
      company: [''],
      estimateNum: [''],
      po: [''],
      invoice: [''],
      dateRange: this.dateRange,
    });
  }

  ngOnInit() {
    this.page_entering_url =window.location.href
    // console.log('>>> current url=%s', encodeURIComponent(this.page_entering_url));
  }

  checkDateRangeValidator() : ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startDate =this.dateRange?.controls['start'],
        endDate = this.dateRange?.controls['end'];

      // console.log("checkDateRangeValidator: start=%s, end=%s", startDate, endDate);

      if(!startDate?.value || !endDate?.value) {
        const error ={ dates_not_set: {value: true}};
        control.markAllAsTouched();
        startDate?.setErrors(error);
        endDate?.setErrors(error)

        return error;
      }

      startDate?.setErrors(null);
      endDate?.setErrors(null)

      return null;  // passed
    }
  }

  reloadData() {
    // console.log('>>> reloadData()');

    this.filteredEstimates =undefined
    this.recLinkedInvoices ={}

    this.loading =true;

    // const startDate = new Date(LocalDateformatPipe.formatDateFunc(this.dateRange.get('start')?.value, 'YYYY-MM-DDT00:00:00') + globals.date_str_tz_offset_suffix);
    // const endDate = new Date(LocalDateformatPipe.formatDateFunc(this.dateRange.get('end')?.value, 'YYYY-MM-DDT23:59:59.999') + globals.date_str_tz_offset_suffix);

    const startDate = this.dateRange.get('start')?.value ? LocalDateformatPipe.instant(this.dateRange.get('start')?.value || undefined, 'YYYY-MM-DD') : '';
    const endDate = this.dateRange.get('end')?.value ? LocalDateformatPipe.instant(this.dateRange.get('end')?.value || undefined, 'YYYY-MM-DD') : '';
    const estimate_num = this.f['estimateNum'].value.toUpperCase().replace(/\s+/,'');
    const company =this.f['company'].value.toUpperCase();
    const po_num =this.f['po'].value.toUpperCase().replace(/\s+/,'');
    const invoice_num =this.f['invoice'].value.toUpperCase().replace(/\s+/,'');

    // console.log('>>> order.reloadData: #1 start=%s, end=%s, estimate=%s, company=%s, po=%s, invoice=%s',
    //   startDate, endDate, estimate_num, company, po_num, invoice_num);

    this.appComponent.systemService.getEstimates(startDate, endDate, this.maxResults, this.nextDbStartPos,
      estimate_num, company, po_num, invoice_num, this.lastConditionsHash)
      .pipe(first()).subscribe({
      next: (res) => {
        // done
        if(res.ok) {
          // console.log('>>> res = %o', res);

          if (this.filteredEstimates === undefined || this.lastConditionsHash !== res.conditions_hash) {
            // first query, or query conditions changed
            this.filteredEstimates =res.Estimate
            this.recLinkedInvoices = res.LinkedInvoice
          } else {
            this.filteredEstimates.push(...res.Estimate)
            this.recLinkedInvoices = {...this.recLinkedInvoices, ...res.LinkedInvoice}
          }

          this.nextDbStartPos = res.nextStartPos
          this.lastSearchDurInMs = res.searchDurInMs

          // reserved for future use
          // this.lastConditionsHash = res.conditions_hash

          this.info_msg = this.appComponent.translate.instant('info.search_res_dur', {
            time: LocalDateformatPipe.formatDateFunc(new Date(), 'hh:mm:ss A'),
            est_cnt: res?.Estimate?.length,
            inv_cnt: Object.keys(res?.LinkedInvoice)?.length,
            dur: res.searchDurInMs
          });

          // this.filterData();
        // } else if(res.result === 'login_qb') {
        //   RedirectToQBLogin()
        } else {
          this.error_msg = this.appComponent.translate.instant(res.result);
        }
        this.submitted = this.loading =false;

      }, error: err => {
        this.error_msg = this.appComponent.translate.instant('error.unable_to_load_rec');
        this.submitted = this.loading =false;
      }
    });

  }

  onClickRetrieveRecs(event:any) {
    this.submitted =true;
    this.info_msg = this.error_msg =''

    if(this.form.invalid) return;

    this.reloadData();
  }

  labelForReservedItem ="待出貨";

  // group all corresponding invoice's Line[] for a given Estimate's Line
  getInvoiceLines(recEst : IEstimate, recEstLine : ILine) : IInvoicedItems {
    if(recEstLine?.linkedInvoiceLines) {
      return recEstLine.linkedInvoiceLines
    }

    const inv_n_line: IInvoiceId_n_Line[] =[]
    let totalReservedQty =0  // sum of invoice qty for Ship-Via = '(待出貨)'

    const linkedItemInvLines = recEst?.LinkedTxn?.map((curr:ILinkedTxn) => {

      // @ts-ignore
      // find the corresponding invoice's Line
      const line =this.recLinkedInvoices?.[curr?.TxnId]?.Line?.find((ln) => ln?.SalesItemLineDetail?.ItemRef?.value === recEstLine.SalesItemLineDetail?.ItemRef?.value && ln?.LinkedTxn?.some((e) => e?.TxnId === recEst.Id))

      if(line) {
        inv_n_line.push({InvId: curr?.TxnId || '', InvLine: line})

        // console.log('>>> ship-via: %o', line)
        // @ts-ignore
        if(this.recLinkedInvoices?.[curr?.TxnId]?.ShipMethodRef?.value?.indexOf(this.labelForReservedItem) > 0) {
          console.log('>>> adding to reserved qty')
          totalReservedQty += line?.SalesItemLineDetail?.Qty
        }
      }

      return line;
    }).filter((e) => !!e)

    const result = recEstLine.linkedInvoiceLines = {
      total_qty: linkedItemInvLines?.reduce((acc, curr) => acc + curr?.SalesItemLineDetail?.Qty, 0) || 0,
      total_reserved_qty: totalReservedQty,
      total_amount: linkedItemInvLines?.reduce((acc, curr) => acc + curr?.SalesItemLineDetail?.Qty * curr?.SalesItemLineDetail?.UnitPrice, 0) || 0,
      corr_invoices: inv_n_line,
      hidden: true
    }

    // console.log('>>> result =%o, ', result)

    // console.log(`>>> recEstLine.Id=${recEstLine.Id}: est.qty=${recEstLine.SalesItemLineDetail.Qty}, est.amt=${recEstLine?.Amount}, `
    //   +`inv.qty=${result.qty}, inv.amt=${result.amount}`)
    //
    return result;
  }

  onClickLineItemIdx(item: IInvoicedItems) {
    item.hidden =!item.hidden
  }
}
