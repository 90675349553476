<form [formGroup]="loginForm" class="form-signin text-center" (ngSubmit)="onSubmit()" >

  <h2 class="form-signin-heading">{{ 'pls.sign.in' | translate}}</h2>

  <div *ngIf="error_msg" id="error_div" class="alert alert-danger" role="alert">
    {{ 'bad_credentials' | translate }}
  </div>
  <div *ngIf="info_msg" id="message_div" class="alert alert-success"
       [innerHTML]="info_msg">
  </div>

  <div class="text-start d-inline-block" >
    <div class="form-field">
      <mat-form-field class="form-field-full w-100">
        <mat-label class="sr-only" [innerHTML]="'caption.email' | translate"></mat-label>
        <input matInput type="text" id="email" formControlName="email"
               placeholder="{{ 'caption.email' | translate }}"
               maxlength="{{ _globals.maxFieldLength }}"
               autofocus
               >
        <mat-icon *ngIf="submitted && f['email']['errors']" matIconSuffix>error</mat-icon>

        @if(submitted && f['email']['errors']){
          <mat-error *ngIf="f['email']?.['errors']?.['required']">{{ 'error.email_is_reqd' | translate }}</mat-error>
          <mat-error *ngIf="f['email']?.['errors']?.['email']">{{ 'error.invalid_email_format' | translate }}</mat-error>
        }
      </mat-form-field>
    </div>

    <div class="form-field">
      <mat-form-field class="form-field-full">
        <mat-label class="sr-only" [innerHTML]="'caption.password' | translate"></mat-label>
        <input matInput [type]="passwordVisible.password ? 'text': 'password'" id="password" formControlName="password"
               placeholder="{{ 'caption.password' | translate }}"
               maxlength="{{ _globals.maxFieldLength }}"
        />
        <button mat-icon-button matSuffix
                (click)="onClickRevealPassword($event, 'password')"
                [attr.aria-label]="'Hide password'"
                type="button"
                >
          <mat-icon>{{passwordVisible.password ? 'visibility' : 'visibility_off'}}</mat-icon>
        </button>
        <mat-icon *ngIf="submitted && f['password']['errors']" matIconSuffix>error</mat-icon>
        @if(submitted && f['password']['errors']){
          <mat-error *ngIf="f['password']?.['errors']?.['required']">{{ 'error.password_is_reqd' | translate }}</mat-error>
        }
      </mat-form-field>
    </div>
  </div>

  <div class="form-group">
    <button [disabled]="loading" class="btn btn-primary btn-block"
            type="submit">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ 'caption.sign_in' | translate }}
    </button>
  </div>
</form>

