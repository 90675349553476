import {Component, DoCheck, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Languages} from './app.config';
import {FooterComponent} from './footer/footer.component';
import { BnNgIdleService } from 'bn-ng-idle';
import {SystemService, UserService} from '../services';
import {HttpServiceSubject} from '../services/http-service-subject';
import {first, Subscription} from 'rxjs';
import {User} from '../models';
import {environment} from '../environments/environment';
import {NavbarComponent} from './navbar/navbar.component';
import {UserNavbarComponent} from './user-navbar/user-navbar.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    TranslateModule,
    NavbarComponent,
    FooterComponent,
    UserNavbarComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit, DoCheck, OnDestroy {
  constructor(public translate: TranslateService,
              public userService: UserService,
              public systemService: SystemService,
              private router: Router,
              private route: ActivatedRoute,
              private bnIdle: BnNgIdleService,
              private httpServiceSubj: HttpServiceSubject,
  ) {
    // set default language to en
    translate.addLangs(Languages);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang && Languages.indexOf(browserLang) >= 0 ? browserLang : 'en');

    this.allSubscriptions.push(this.userService.currentUser.subscribe(u => {
      this.user = u;
      // console.log('>>> app-root: sub: user=%o', u);
    }));

    // check if server session exists
    this.userService.checkSession().pipe(first()).subscribe({
      next: (u) => {
        this.user = u;
        // console.log('>>> app-root: constructor.checkSession: user=%o', u);
      },
      error: err => {
        // console.log('app-root.checkSession(): err=%o', err);
      }
    });

    // arbitrary initial timeout of 1 hour
    // console.log('>>> session timer starts watching');
    this.bnIdle.startWatching(58*60).subscribe((res) => {
      if(res) {
        console.log("%s: app.component >>> session expired: email=%s", new Date(), this.user.email);

        if(this.user?.email) {
          this.userService.logout().pipe(first()).subscribe({
            next: () => {},
            error: (err) => { this.userService.kickoutCurrentUser() }
          });
        }
      }
    });

    this.httpServiceSubj.accessHttp.subscribe(
      (res) => {
        // console.log('>>> accessHttp: %s', res);
        this.restartSessionTimer();
      });

  }

  title =''
  allSubscriptions: Subscription[] =[];
  user: User = new User();

  restartSessionTimer() {
    if(this.user?.email) {
      const timeoutInMins = this.user?.sessionTimeout ?? 0;
      if(!timeoutInMins) return;

      this.bnIdle.resetTimer(timeoutInMins * 60);

      console.log("%s: app.component >>> restart session timer: %s mins, email=%s", new Date(), timeoutInMins, this.user.email);
    }
  }

  ngOnInit() {
    // console.log('app-root:ngOnInit: %s', new Date());

    console.log('host=%s', window.location.origin);
    if(!environment.production) {
      console.log('%s: app.OnInit: api=%s',
        new Date(), environment.api_url);
    }
  }

  ngDoCheck(): void {
    this.title = this.translate.instant('default.page.title');

  }

  ngOnDestroy(): void {
    while(this.allSubscriptions.length) {
      this.allSubscriptions.pop()?.unsubscribe();
    }
  }

}
