<form [formGroup]="form" class="text-center" >

  <h2 class="text-center form-signin-heading">{{ 'caption.search_orders.description' | translate}}</h2>

  <div class="text-center"><i>{{ '(Display max. ' + maxResults +' Estimate records)' }}</i></div>

  <div *ngIf="error_msg" id="error_div" class="alert alert-danger" role="alert"
       [innerHTML]="error_msg">
  </div>

  <div *ngIf="info_msg" id="message_div" class="alert alert-success"
       [innerHTML]="info_msg">
  </div>

  <div class="form-group">
    <div class="row g-3 align-text-top">
      <div class="col-auto">
        <mat-form-field>
          <mat-label [innerHTML]="'caption.select_date_range' | translate"></mat-label>

          <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker" >
            <input matStartDate formControlName="start" [placeholder]="'caption.start_date' | translate ">
            <input matEndDate formControlName="end" [placeholder]="'caption.end_date' | translate ">
          </mat-date-range-input>

          <mat-hint>YYYY-MM-DD - YYYY-MM-DD</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="submitted && dateRange?.['errors']?.['dates_not_set']">
            {{ 'error.dates_unset' | translate }}
          </mat-error>

        </mat-form-field>
      </div>

      <div class="col-auto">
        <mat-form-field>
          <mat-label  [innerHTML]=" 'caption.estimate_no' | translate "></mat-label>
          <input matInput type="text" id="estimateNum" formControlName="estimateNum"
                 maxlength="{{ _globals.maxFieldLength }}"
                 (keydown.enter)="$event.preventDefault()"
          >
        </mat-form-field>
      </div>

      <div class="col-auto">
        <mat-form-field>
          <mat-label  [innerHTML]=" 'caption.company' | translate "></mat-label>
          <input matInput type="text" id="company" formControlName="company"
                 maxlength="{{ _globals.maxFieldLength }}"
                 (keydown.enter)="$event.preventDefault()"
          >
        </mat-form-field>
      </div>

      <div class="col-auto">
        <mat-form-field>
          <mat-label  [innerHTML]=" 'caption.po' | translate "></mat-label>
          <input matInput type="text" id="po" formControlName="po"
                 maxlength="{{ _globals.maxFieldLength }}"
                 (keydown.enter)="$event.preventDefault()"
          >
        </mat-form-field>
      </div>

      <div class="col-auto">
        <mat-form-field>
          <mat-label  [innerHTML]=" 'caption.invoice_number' | translate "></mat-label>
          <input matInput type="text" id="invoice" formControlName="invoice"
                 maxlength="{{ _globals.maxFieldLength }}"
                 (keydown.enter)="$event.preventDefault()"
          >
        </mat-form-field>
      </div>

      <div class="col-auto align-top">
        <button class="btn btn-primary mt-3"
                (click)="onClickRetrieveRecs($event)"
                type="button">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ 'caption.search' | translate }}
        </button>
      </div>

    </div>

    <div *ngIf="filteredEstimates" class="mt-2">
      <table class="table table-secondary table-bordered" >
        <thead class="table-primary fw-bolder nrg-table-header">
        <tr>
          <td>#</td>
          <td [innerHTML]="'caption.estimate_no' | translate" ></td>
          <td [innerHtml]="'caption.status' | translate" ></td>
          <td [innerHTML]="'caption.date' | translate" ></td>
          <td [innerHtml]="'caption.company' | translate" ></td>
          <td *ngIf="user?.hasRole('ROLE_SUPERUSER')" [innerHtml]="'caption.total_amount' | translate" ></td>
          <td [innerHtml]="'caption.po' | translate" ></td>
        </tr>
        </thead>

        <tbody class="nrg-table-body">
          @for(recEst of filteredEstimates; let i=$index; track recEst.Id ) {
            <tr>
              <td>{{ i+1 }}</td>
              <td [innerHTML]="recEst?.DocNumber" ></td>
              <td [innerHTML]="recEst?.TxnStatus" ></td>
              <td [innerHTML]="recEst?.MetaData?.CreateTime |localDateformat:'YYYY-MM-DD'" ></td>
              <td [innerHTML]="recEst?.CustomerRef?.name"></td>
              <td *ngIf="user?.hasRole('ROLE_SUPERUSER')" [innerHTML]="recEst?.CurrencyRef?.value +' ' +(recEst?.TotalAmt|number: '1.0-0')"></td>
              <td [innerHTML]="recEst?.CustomField?.[0]?.StringValue "></td>
            </tr>

            <tr>
              <td style="white-space: nowrap"></td>
              <td colspan="6">
                <table class="table table-hover table-bordered">
                  <thead class="table-hover fw-bold nrg-table-header" >
                  <tr>
                    <td>#</td>
                    <td class="text-start" [innerHTML]="'caption.item_desc' | translate" ></td>
                    <td *ngIf="user?.hasRole('ROLE_SUPERUSER')" [innerHTML]="'caption.unit_price' | translate" ></td>
                    <td [innerHTML]="'caption.qty' | translate" ></td>
                    <td *ngIf="user?.hasRole('ROLE_SUPERUSER')" [innerHTML]="'caption.subtotal' | translate" ></td>
                    <td [innerHTML]="'caption.remaining_qty' | translate" ></td>
                    <td *ngIf="user?.hasRole('ROLE_SUPERUSER')" [innerHTML]="'caption.remaining_balance' | translate" ></td>
                  </tr>
                  </thead>

                  <tbody class="nrg-table-body">
                  @for(recEstLine of recEst.Line; let j=$index; track recEstLine ) {
                    @if(recEstLine?.DetailType === 'SalesItemLineDetail') {
                      <tr>
                        @let linkedInvoiceLines = getInvoiceLines(recEst, recEstLine);

                        <td>
                          @if(linkedInvoiceLines.corr_invoices.length > 0) {
                            <a class="yellow-on-black" [routerLink]="[]" (click)="onClickLineItemIdx(linkedInvoiceLines)">{{ j+1 }}</a>
                          } @else {
                            {{ j+1 }}
                          }
                        </td>

                        <td class="text-start td-inv-item-desc" [innerHTML]="recEstLine?.Description"></td>
                        <td *ngIf="user?.hasRole('ROLE_SUPERUSER')" [innerHTML]="'$ ' +(recEstLine?.SalesItemLineDetail?.UnitPrice | number:'1.0-0')"></td>
                        <td [innerHTML]="recEstLine?.SalesItemLineDetail?.Qty | number:'1.0-0'"></td>
                        <td *ngIf="user?.hasRole('ROLE_SUPERUSER')" [innerHTML]="'$ ' +(recEstLine?.Amount | number:'1.0-0')"></td>
                        <td>
                          <span [ngClass]="{'special-red'  : recEstLine.SalesItemLineDetail.Qty > linkedInvoiceLines.total_qty }"
                            [innerHTML]="recEstLine.SalesItemLineDetail.Qty - linkedInvoiceLines.total_qty | number:'1.0-0'">
                          </span>

                          <span *ngIf="linkedInvoiceLines.total_reserved_qty > 0" class="special-purple"
                             [innerHTML]="' ('+labelForReservedItem +' ' + (linkedInvoiceLines.total_reserved_qty| number:'1.0-0') +')' ">
                          </span>

                        </td>
                        <td *ngIf="user?.hasRole('ROLE_SUPERUSER')" [innerHTML]="'$ ' +(recEstLine.Amount - linkedInvoiceLines.total_amount | number:'1.0-0')"></td>
                      </tr>

                      <tr *ngIf="!linkedInvoiceLines.hidden">
                        <td style="white-space: nowrap"></td>
                        <td colspan="6">
                          <table class="table table-info table-bordered">
                            <thead class="table-info fw-bolder nrg-table-header">
                            <tr>
                              <td [innerHTML]="'caption.invoice_number' | translate" ></td>
                              <td [innerHTML]="'caption.ship_date' | translate" ></td>
                              <td [innerHTML]="'caption.ship_method_ref' | translate" ></td>
                              <td *ngIf="user?.hasRole('ROLE_SUPERUSER')" [innerHTML]="'caption.unit_price' | translate" ></td>
                              <td [innerHTML]="'caption.qty' | translate" ></td>
                              <td *ngIf="user?.hasRole('ROLE_SUPERUSER')" [innerHTML]="'caption.subtotal' | translate" ></td>
                            </tr>
                            </thead>

                            <tbody class="nrg-table-body">
                              @for(inv_line of linkedInvoiceLines.corr_invoices; let k=$index; track inv_line) {
                                <tr>
                                  <td [innerHTML]="recLinkedInvoices[inv_line.InvId].DocNumber"></td>
                                  <td [innerHTML]="recLinkedInvoices[inv_line.InvId].ShipDate"></td>
                                  <td [innerHTML]="(recLinkedInvoices[inv_line.InvId].ShipMethodRef?.value || '')
                                  + ' '+ (recLinkedInvoices[inv_line.InvId].TrackingNum ? '[' +recLinkedInvoices[inv_line.InvId].TrackingNum +']' : '') "
                                  ></td>
                                  <td *ngIf="user?.hasRole('ROLE_SUPERUSER')" [innerHTML]="'$ '+(inv_line.InvLine.SalesItemLineDetail.UnitPrice | number: '1.0-0')"></td>
                                  <td [innerHTML]="inv_line.InvLine.SalesItemLineDetail.Qty | number: '1.0-0'"></td>
                                  <td *ngIf="user?.hasRole('ROLE_SUPERUSER')" [innerHTML]="'$ '+((inv_line.InvLine.SalesItemLineDetail.UnitPrice || 0) * (inv_line.InvLine.SalesItemLineDetail.Qty || 0) | number: '1.0-0')"></td>
                                </tr>
                              }  <!-- @for(inv_line of linkedInvoiceLines.corr_invoices; let k=$index; track inv_line) -->
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    }   <!-- @if(recEstLine?.DetailType === 'SalesItemLineDetail') -->
                  }   <!-- @for(recEstLine of recEst.Line; let j=$index; track recEstLine ) -->
                  </tbody>
                </table>
              </td>
            </tr>
          }    <!-- @for(recEstimate of filteredEstimates; let i=$index; track recEstimate.Id ) { -->
        </tbody>
      </table>
    </div>

  </div>

</form>
