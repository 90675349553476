import {environment} from '../environments/environment';

export function clearAllFormControlsErrors(form : any) {
  Object.keys(form.controls).forEach(key => {
    const errors =form.get(key)?.errors;
    if(errors != null) {
      Object.keys(errors).forEach(keyError => {
        form.controls[key].setErrors(null)
      })
    }
  })

}

export function RedirectToQBLogin(return_url =''){
  if(!return_url) return_url=window.location.protocol +'//' +window.location.host + '/login'

  console.log(`>>> redirect to: ${environment.api_url}/QBOSignIn, ${return_url}`)

  window.location.href =`${environment.api_url}/QBOSignIn?return_url=${encodeURIComponent(return_url)}`;
}
