<nav class="navbar navbar-expand-md navbar-light bg-light">
  <div class="container-fluid px-2 gap-2">
    <button class="navbar-toggler" type="button"
            (click)="collapseMenu=!collapseMenu"
            data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon btn"></span>
    </button>
    <div class="navbar-collapse" [ngClass]="collapseMenu ? 'collapse' : ''" id="navbarNavDropdown">
      <ul class="navbar-nav nav-tabs ms-2 me-2 gap-2">

        <!-- Nav Item : Login -->
        <li *ngIf="!user.email" class="nav-item " >
          <a class="nav-link" id="navitem_login" routerLink="/login" skipLocationChange="true"
             routerLinkActive="active" ariaCurrentWhenActive="page">
            {{ 'caption.sign_in' | translate }}
          </a>
        </li>

        <li *ngIf="user.hasRole('ROLE_ADMIN')" class="nav-item "  >
          <!-- Nav Item : login_qb -->
          <a class="nav-link" id="navitem_orders" routerLink="/qb_token" skipLocationChange="true"
             (click)="collapseMenu=true"
             routerLinkActive="active" ariaCurrentWhenActive="page">
            {{ 'caption.qb_token' | translate }}
          </a>
        </li>

        <li *ngIf="user.hasRole('ROLE_USER')" class="nav-item "  >
          <!-- Nav Item : search orders -->
          <a class="nav-link" id="navitem_orders" routerLink="/search_orders" skipLocationChange="true"
             (click)="collapseMenu=true"
             routerLinkActive="active" ariaCurrentWhenActive="page">
            {{ 'caption.orders' | translate }}
          </a>
        </li>

        <li *ngIf="user.hasRole('ROLE_ADMIN')" class="nav-item "  >
          <!-- Nav Item : inventory summary -->
          <a class="nav-link" id="navitem_orders" routerLink="/inventory_summary" skipLocationChange="true"
             (click)="collapseMenu=true"
             routerLinkActive="active" ariaCurrentWhenActive="page">
            {{ 'caption.inventory' | translate }}
          </a>
        </li>

        <li *ngIf="user.hasRole('ROLE_SUPERUSER')" class="nav-item "  >
          <!-- Nav Item : Audit Log -->
          <a class="nav-link" id="navitem_auditlog" routerLink="/auditLog" skipLocationChange="true"
             (click)="collapseMenu=true"
             routerLinkActive="active" ariaCurrentWhenActive="page">
            {{ 'caption.audit_log' | translate }}
          </a>
        </li>

        <li *ngIf="user.hasRole('ROLE_SUPERUSER')" class="nav-item "  >
          <!-- Nav Item : new user -->
          <a class="nav-link" id="navitem_newuser" routerLink="/newuser" skipLocationChange="true"
             (click)="collapseMenu=true"
             routerLinkActive="active" ariaCurrentWhenActive="page">
            {{ 'caption.add_new_user' | translate }}
          </a>
        </li>

        <li *ngIf="user.hasRole('ROLE_SUPERUSER')" class="nav-item "  >
          <!-- Nav Item : edit user -->
          <a class="nav-link" id="navitem_useradmin" routerLink="/userAdmin" skipLocationChange="true"
             (click)="collapseMenu=true"
             routerLinkActive="active" ariaCurrentWhenActive="page">
            {{ 'caption.edit_user' | translate }}
          </a>
        </li>

        <li *ngIf="user.hasRole('ROLE_SUPERUSER')" class="nav-item "  >
          <!-- Nav Item : edit settings -->
          <a class="nav-link" id="navitem_settings" routerLink="/appsettings" skipLocationChange="true"
             (click)="collapseMenu=true"
             routerLinkActive="active" ariaCurrentWhenActive="page">
            {{ 'caption.appsettings' | translate }}
          </a>
        </li>

        <li *ngIf="user.email" class="nav-item " >
          <a class="nav-link" id="navitem_password" routerLink="/password" skipLocationChange="true"
             (click)="collapseMenu=true"
             routerLinkActive="active" ariaCurrentWhenActive="page">
            {{ 'caption.password' | translate }}
          </a>
        </li>

      </ul>
    </div>

    <div *ngIf="user.email" class="align-self-center d-flex gap-2" >
      <button [disabled]="loading" class="btn btn-sm btn-outline-success " type="button" (click)="logout()">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ 'caption.logout'  | translate }}
      </button>
    </div>

  </div>
</nav>

