<div class="text-center" >
  <div class="fs-3 fw-bold">{{ 'welcome' | translate }}</div>
</div>

<br>

<div class="text-center" >
  <span class="fw-bold" [innerHTML]="'caption.company' | translate" ></span> :
  <span class="col-10 align-items-center" [innerHTML]="user.company" ></span>
</div>

<div class="text-center" >
  <span class="fw-bold" [innerHTML]="'caption.fullname' | translate" ></span> :
  <span class="col-10 align-items-center" [innerHTML]="user.fullName" ></span>
</div>

